import React from 'react';
import './styles.css';

const Logo = () => {
  return (
    <div className="logoContainer">
      <img src='/playground_assets/logo60.png' className="logo" alt="logo" />
      <div className="logoTitle">RTFM</div>
    </div>
  );
};

export default Logo;
